/*JS MOBILE*/
$('<button class="close_modal" onclick="hideAchatExpress.call(this);"></button>').appendTo(".achat_express");

/*/ Add search bar icon in menu mobile top /*/
$('<div id="search_icon" class="header_section search_icon" onclick="showhide_search();"><span></span></div>').insertBefore("header .banner_nav .header_section.logo");

/*/ Toggle new search bar v2/*/
function showhide_search() {
    $('.block_top_item #form_recherche').slideToggle().toggleClass('activated');
    $('body').toggleClass('searching');

    var topSearch = $( '.block_top_item #form_recherche' ).hasClass( 'activated' );

    if (topSearch) {
        $( "#shade" ).addClass('visible');
        $("#shade").css('z-index', '25');
        $( "body" ).removeClass( 'search_hidden' );
        $( '.block_top_item #form_recherche' ).addClass('activated');
    } else {
        $("#shade").removeClass('visible');
        $( "body" ).addClass('search_hidden');
        $( '.block_top_item #form_recherche' ).removeClass('activated');
    }

    //Check if account menu is open, if open close it
    if($('#account_menu').hasClass('active')) {
        $('#account_menu').removeClass('active');
    }
}

// Close recherche mobile by shade
$( document ).on( 'click', '#shade.visible', function () {
    $("body").removeClass("noscroll");
    $(".block_top_item #form_recherche").removeClass("activated");
    $( "#shade" ).removeClass( 'visible' );
    $( ".homepage" ).addClass( 'search_hidden' );
    $(".block_top_item #form_recherche").css('display', 'none');
});

// v2 code
function toggleViewMobile() {
    $( 'div.toggleNumObjects span' ).toggleClass( 'actif' );
    if ( $("#oneProduct").hasClass('actif')) {
        $('.twoProducts').removeClass('actif');
        $('div.swiper-slide').addClass('full');
        createCookie( 'nb_produits', 1, 1 );
    } else {
        $('div.swiper-slide').removeClass('full');
        $('.oneProduct').removeClass('actif');
        createCookie( 'nb_produits', 2 );
    }
}

// Toggles filters submenu visibility mobile
function toggleFilters() {
    if ($(this).find('.selected_options').hasClass('actif')) {
        $( this ).find( '.selected_options' ).removeClass( 'actif' );
        $(this ).find('.filter_name').removeClass('actif');
    } else {
        $('.selected_options').removeClass('actif');
        $('.filter_name').removeClass('actif');
        $( this ).find( '.selected_options' ).addClass( 'actif' );
        $(this).find('.filter_name').addClass('actif');
    }
}

function searchV2Shad() {
    setTimeout(function () {
        $("#shade").removeClass('visible');
    }, 1500);
}

$(function () {
    /*Ajout typo mobile*/
    $('head').append('<link rel="preconnect" href="https://fonts.googleapis.com">');
    $('head').append('<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>');
    $('head').append('<link href="https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&family=Rokkitt:wght@300;400;500;700&display=swap" rel="stylesheet">');
    $('head').append('<link rel="stylesheet" href="https://use.typekit.net/mhr4gec.css">');
    $('head').append('<link rel="stylesheet" href="https://use.typekit.net/agd6lrf.css">');

    /*Account dropdown from menu*/
    if ($('#left_menu .bloc_lnk.user.connected.dropdown').length > 0) {
        $(document).on('click tap touch', '.bloc_lnk.user.connected.dropdown', function() {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).parent().find('.dropdown.mon-compte').slideUp(300);
            } else {
                $(this).addClass('active');
                $(this).parent().find('.dropdown.mon-compte').slideDown(300);
            }
        });
    }

    // Politique de Confidentialite horizontal scroll on Mobile
    if ($('#bloc_5 table').length) {
        $('#bloc_5 table').wrapAll('<span id=\"polConf-table\" />');

        window.setTimeout(function () {
            $('#polConf-table').overlayScrollbars({});
        }, 300);
    }

    // Politique de Confidentialite horizontal scroll on Mobile EN which is in a different block
    if ($('#bloc_6 table').length) {
        $('#bloc_6 table').wrapAll('<span id=\"polConf-table-2\" />');

        window.setTimeout(function () {
            $('#polConf-table-2').overlayScrollbars({});
        }, 300);
    }

    // Init swipers Mon compte
    if ($('#menu_account_slider_mob .swiper-slide').length > 1) {
        var menuInitialSlide = $('#menu_account_slider_mob .swiper-slide.actif').index();

        setTimeout(function() {
            new Swiper('#menu_account_slider_mob.swiper-container', {
                slidesPerView: 2.5,
                centeredSlides: true,
                initialSlide: menuInitialSlide,
                navigation: {
                  nextEl: "#wrap_swiper .swiper-button-next",
                  prevEl: "#wrap_swiper .swiper-button-prev",
                }
            });
        }, 800);
    }

    // Chargement de la tarification
    if ($("#tarification_load").length > 0) {

        // Chargement des différentes tarification
        $.ajax({
            url: path_relative_root + 'ajax_tarification_list.php',
            type: 'post',
            dataType: 'json',
            success: function (res) {
                if (res !== []) {
                    var div = $('<div>', {'class': 'custom-select custom-select-footer'});
                    var select = $('<select id="country_tarif" name="country">');
                    $.each(res, function (key, val) {
                        var option = $("<option />", {value: key, text: val.pays_nom});
                        if (val.selected) {
                            option.prop('selected', true)
                        }
                        $(option).appendTo(select);
                    });

                    // Event sur le select lorsque l'on change de pays
                    select.on('change', function () {
                        var country_iso = $(this).val();

                        // On va envoyé au serveur que l'on change de pays
                        $.ajax({
                            url: path_relative_root + create_link('ajax_tarification_change_country'),
                            type: 'post',
                            data: 'iso=' + country_iso,
                            success: function () {
                                location.reload();
                            }
                        });
                    });

                    select.appendTo(div);
                    div.appendTo($("#tarification_load"));
                } else {
                    $("#tarification_load").css('display', 'none');
                }
            }
        });
    }

    window.setTimeout(function () {
        /*Recherche*/
        $("#completeDiv").appendTo("#top_search");

        if ($('#search_trigger').length) {
            var trigger = $('#search_trigger .ico');
            var search_input = $('#top_search');

            /*Adding close btn*/
            search_input.append('<span class="close_search" />');

            trigger.on('click touch', function() {
                searchTrigger();
            });

            $('.close_search').on('click touch', function() {
                searchTrigger();
            });
        }

        if ($("#sliderBando .swiper-slide").length > 1) {
            var bandoHeadermobile = new Swiper("#sliderBando", {
                slidesPerView: 1,
                loop: true,
                speed: 1500,
                simulateTouch: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                effect: "slide",
                direction: "horizontal",
                paginationClickable: true,
                preloadImages: true,
                lazyLoading: true,
            });
        }

        if ($('#hashtag_module_frame .swiper-slide').length > 1) {
            new Swiper('#hashtag_module_frame', {
                slidesPerView: 2.25,
                initialSlide: 1,
                spaceBetween: 20,
                loop: true,
            });
        }

        // Init swiper
        if ($('.tg-module-1 #moduleSwiper').length) {
            // iterate through all swipers with the same id
            $(".tg-module-1 #moduleSwiper").each(function (index) {
                var $this = $(this);
                $this.addClass("s-" + index);
                $this.parent().find('.swiper-button-prev').addClass("btn-prev-" + index);
                $this.parent().find('.swiper-button-next').addClass("btn-next-" + index);
                var tgSwiper = new Swiper(".s-" + index, {
                    preloadImages: false,
                    lazy: true,
                    loop: false,
                    autoHeight: true,
                    slidesPerView: 2,
                    spaceBetween: 10,
                    navigation: {
                        nextEl: ".btn-next-" + index,
                        prevEl: ".btn-prev-" + index,
                    }
                });

            });
        }

        if ($('.pushCategSlider .swiper-slide').length > 4) {
            new Swiper('.pushCategSlider', {
                slidesPerView: 'auto'
            });
        } else if ($('.pushCategSlider .swiper-slide').length < 4) {
            $('.pushCategSlider').addClass('calibrate');
        }

        if ($('#selectionOfferteContainer .ligne_produit_offert').length > 2) {
            $('#selectionOfferteContainer .wrapper_product').overlayScrollbars({});
        }

    }, 600);

    /*FOOTER LINKS*/
    if ($('.SEO').length) {
        var links_trigger = $('.SEO .col_title');

        links_trigger.each(function() {
            var links_content = $(this).next($('.col_content'));

            $(this).on('click touch', function() {
                if (links_content.hasClass('unfold')) {
                    $(this).removeClass('open');
                    links_content.slideToggle();
                    links_content.removeClass('unfold');
                } else {
                    $('.SEO .col_content').not(links_content).removeClass('unfold');
                    $('.SEO .col_content').not(links_content).slideUp();
                    $('.SEO .col_title').not($(this)).removeClass('open');
                    $(this).addClass('open');
                    links_content.slideToggle();
                    links_content.addClass('unfold');
                }
            });
        });
    }

    window.setTimeout(function () {
        if ($('.color_list .swiper-slide').length > 1) {
            colorList = new Swiper(".color_list", {
                slidesPerView: 'auto',
                spaceBetween: 15,
                loop: false,
                observer: true,
                observeParents: true,
            });
        }

        if ($('.size_list .swiper-slide').length > 1) {
            sizeList = new Swiper(".size_list", {
                slidesPerView: 'auto',
                spaceBetween: 15,
                loop: false,
                observer: true,
                observeParents: true,
            });
        }
    }, 600);

    if ($(".login_form_wrapper .w-newlogin-form").length) {
        var $deja_compte = Translator.translate('existing_user_pwd_reset_' + site_id);

        $(".login_form_wrapper .w-newlogin-form").append( $('<p class="deja_compte">' + $deja_compte + '</p>'));
    }

    initMultilpleSuperSwiper();
});

function closeMultiShad(id) {
    modBoxClose();
    $("#" + id).removeClass('open');
    $("#availabilityInStore").slideUp(300);
    $("#" + id).fadeOut();
    $('#general_shade').css('display', 'none');
    $('#shade').fadeOut();
    $("#shade").removeClass("forMenu for_store");
    $("body").css("overflow", "auto");
}

function searchTrigger() {
    var wrap_header = $('.banner_wrapper');
    var search_input = $('#top_search');
    var shad = $('#shade');

    if (!wrap_header.hasClass('search_open')) {
        search_input.show();
        wrap_header.addClass('search_open');
        $('body').addClass('field_focused');
        search_input.find('#search_home').focus();
        shad.addClass('visible searching');
    } else {
        search_input.hide();
        wrap_header.removeClass('search_open');
        search_input.find('#search_home').blur();
        $('body').removeClass('field_focused');
        shad.removeClass('visible searching');
    }
}

/*FP*/
$(window).on('load', function() {
    if ($("body").hasClass("product_page")) {
        setTimeout(function() {

            /*CROSS SELL FP*/
            if ($('.product_page #gondole_look').length) {
                new Swiper('.product_page #gondole_look', {
                    slidesPerView: 2.5,
                    spaceBetween: 16,
                    loop: false,
                });
            }

            if ($('.product_page #gondole_aimerez').length) {
                new Swiper('.product_page #gondole_aimerez', {
                    slidesPerView: 2.5,
                    spaceBetween: 16,
                    loop: false,
                });
            }
        }, 1000);
    }
});

if ($("body").hasClass("product_page")) {
    setTimeout(function() {
        if ($('#carousel_wrapper .swiper-container.assos_rayon').length) {
            $('#carousel_wrapper .assoc_carousel_wrapper .assoc_carousel_title:contains("aimerez")').closest('.assoc_carousel_wrapper').addClass('aimerez');
        }
    }, 1000);
}

// Commande mobile start
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {
    var divId = 'popup_numero_retour_'+(multiTransp ? idOrderTransp : idOrder);
    var numDIVObjet = document.getElementById(divId);

    numDIVObjet.style.display = "block";
    numDIVObjet.style.opacity = "1";
    numDIVObjet.style.zIndex = "32";
    document.getElementById("general_shade").style.display = "block";
    document.getElementById("general_shade").style.opacity = ".2";
    document.getElementById("general_shade").style.position = "fixed";
    document.getElementById("general_shade").style.zIndex = "31";
    document.getElementById("general_shade").style.left = "0";

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);

    $("html, body").animate({ scrollTop: 0 }, (speed * 2), function () { });
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp) {
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;
    } else {
        data = 'idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;
    }

    $.ajax({
        url : path_relative_root + 'ajax_create_etiquette.php',
        type : 'post',
        data : data,
        success : function (res) {
            if ( res.substr(0,5) == 'false' ) {
                alert(Translator.translate('js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var formId = '#return_products_form';
    formId += '_'+(multiTransp?idOrderTransp:idOrder);

    var has_errors = false;

    $(formId+" .return_quantity").each(function () {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $(formId).serialize();
        data += '&idOrder=' + (multiTransp?idOrderTransp:idOrder) + '&multiTransp=' + multiTransp;

        $.ajax({
            url: path_relative_root + 'ajax_create_etiquette.php',
            type: 'post',
            data: data,
            success: function (res) {
                location.reload();
            }
        });
    }
}
// Commande mobile end

/*FAQ*/
function faqinit(array_questions) {
    $(document).on('change keyup focus', '#faq_search', function () {
        $('#theme_page .theme').each(function() {
            $(this).removeClass('active');
        });

        showTabFaq();

        var search = $(this).val();

        if (search && search.length >= 3) {

            $('.search_results li').show().removeClass("hasMatch");
            $('#theme_global').removeClass('actif');
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
                $('#theme_global').addClass('actif');
            }
        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
            $('#theme_global').addClass('actif');
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        var retour = $('#reset_faq');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display', 'none');
        retour.addClass('actif');
        theme.addClass('actif');
        theme.show();
        showTabFaq(id, 1)
    });

    $('#reset_faq').on('click', function () {
        var theme = $('#theme_page');
        var retour = $('#reset_faq');
        $('#theme_global').addClass('actif');
        theme.removeClass('actif');
        theme.hide();
        retour.removeClass('actif');
        showTabFaq();
    });

    $('#theme_page .theme').on('click', function () {
        var id = $(this).data('id');
        showTabFaq(id, 1);

        if (!$(this).hasClass('active')) {
            $('#theme_page .theme').each(function() {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });
}

function researchFaq(node_id, level, question_id) {
    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({ scrollTop: $(".faq_question_" + question_id + "").offset().top - 100 }, 1000);

    $('#theme_page .theme[data-id='+node_id+']').addClass('active');
}
setTimeout(function() {
    if ($('.bloc_sub_categ .swiper-slide').length > 3) {
        var rayon_menu_slide
        $('.bloc_sub_categ .swiper-slide').each(function (i, obj) {
            if ($(obj).hasClass('active')) {
                rayon_menu_slide = i;
            }
        });

        new Swiper('.bloc_sub_categ.swiper-container', {
            slidesPerView: 'auto',
            initialSlide: rayon_menu_slide,
            centeredSlides: true,
            loop: true,
            navigation: {
              nextEl: ".swiper-button.swiper-button-next",
              prevEl: ".swiper-button.swiper-button-prev",
            },
        });
    }
}, 800);

setTimeout(function() {
    $('#wrapper_eclat_filtre').on('click touch', function () {
        $(this).find('input').removeAttr("onchange");
    });
}, 500);

function openMultiShad(id) {
    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {
        $("#" + id).slideDown(600);
        if ($("#" + id).hasClass('closed')) {
            $("#" + id).removeClass('closed');
        }
    });

    if (id == 'lightbox_achat_express') {
        $("#" + id).css("transform", "translateX(0)");
    }

    if (id == 'popup_cofidis3cb') {
        // COFIDIS FP
        if ($('#popup_cofidis3cb .popup_container').length) {
            $('#popup_cofidis3cb .popup_container').overlayScrollbars({});
        }
    }

    if (id !== 'zoomBox') {
        $("#shad").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
            closeMultiShad(id);
        });
    }

    if (id === "zoomBox") {
        $('#zoom_box_wrapper .swiper-slide').on('click', function () {

            closeMultiShad('zoomBox');
            $("body").css("position", "relative");
        });
    }

    if ($("#buyNowSwiper").length > 0) {
        window.setTimeout(function () {
            buyNowSwiper = new Swiper("#buyNowSwiper", {
                slidesPerView: 1,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }, 50);
    }

    if (id === "sizeguideBox") {
        window.setTimeout(function () {
            $("#wrap_itable").overlayScrollbars({});
        }, 300);

        $('body').css('position','fixed');
    }

    // Shipup Lightbox
    if (id === "shipup-container") {
        // We check if theres the top-navbar and we add a close button to the lightbox
        if ($('.shipup-top-navbar', "#" + id).length && !$('.custom_shipup_close', "#" + id + " .shipup-top-navbar").length) {
            setTimeout(function() {
                $('.shipup-top-navbar', "#" + id).append('<div class="custom_shipup_close" onclick="closeMultiShad(\'' + id + '\')"><span class="bfr bgSprite cross"></span></div>');
            }, 500);
        }

        setTimeout(function() {
            $("#" + id).find('.shipup-home-container').overlayScrollbars({
                scrollbars: {
                    autoHide: "leave",
                    autoHideDelay: 0,
                }
            });
        }, 500);
    }

    if (id === "availabilityInStore") {
        $("#shade").addClass("for_store").fadeTo("slow", 1);
        $("body").css("overflow", "hidden");
    }
}

// Custom Select Menu
function initCustomSelect(select) {
    var x, i, j, selElmnt, a, b, c;

    if (select === undefined) {
        select = 'custom-select';
    }

    x = document.getElementsByClassName(select);

    // Si jamais l'element est déja customisé alors on n'y touche pas
    if ($('.' + select + ' .select-items').length > 0) {
        return;
    }
    for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");

        for (j = 0; j < selElmnt.length; j++) {
            // Si la valur est vide alors on ne la prend pas en compte
            if (selElmnt.options[j].value === '') {
                continue;
            }

            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;

            if (selElmnt.options[j].selected) {
                c.setAttribute("class", "same-as-selected");
            }

            c.addEventListener("click", function (e) {
                var y, i, k, s, h, l;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                h = this.parentNode.previousSibling;
                l = s.length;

                for (i = 0; i < l; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;

                        // Trigger pour lancer l'evènement
                        $(s).trigger('change');
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");

                        for (k = 0; k < y.length; k++) {
                            y[k].removeAttribute("class");
                        }

                        // Pour signaler que c'est cet élément qui doit être sélectionné
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
            });

            b.appendChild(c);
        }

        x[i].appendChild(b);

        a.addEventListener("click", function (e) {
            e.stopPropagation();

            // Raz de tous les autres select
            closeAllSelect(this);

            // On cache/affiche la liste
            this.nextSibling.classList.toggle("select-hide");

            // On change la direction de la fleche
            this.classList.toggle("select-arrow-active");
        });
    }
}

function updateCustomSelect(select_str) {
    if (select_str === undefined) {
        select_str = '.custom-select';
    }

    var select = $(select_str);

    // Evenement sur le select pour bien le gérer
    $('.select-selected', select).on("click", function (e) {
        e.stopPropagation();

        // Raz de tous les autres select
        closeAllSelect(this);

        // On cache/affiche la liste
        this.nextSibling.classList.toggle("select-hide");

        // On change la direction de la fleche
        this.classList.toggle("select-arrow-active");
    });

    // Maintenant pour chacun de ses fils on va mettre un evenement
    $('.select-items div', select).on('click', function () {
        var y, i, k, s, h, l;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        h = this.parentNode.previousSibling;
        l = s.length;

        for (i = 0; i < l; i++) {
            // En parcourant le select on va conparer le innerhtml du select avec le personalisé pour selectionner le bon element
            if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                // Trigger pour lancer l'evènement
                $(s).trigger('change');
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");

                for (k = 0; k < y.length; k++) {
                    y[k].removeAttribute("class");
                }

                // Pour signaler que c'est cet élément qui doit être sélectionné
                this.setAttribute("class", "same-as-selected");
                break;
            }
        }
    });
}

function closeAllSelect(elmnt) {
    var x, y, i, arrNo = [];

    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");

    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }

    for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}

document.addEventListener("click", closeAllSelect);

$(function () {
    window.setTimeout(function () {
        initCustomSelect();
        $(".custom-select select").each(function () {
            var count = $(this).find("option").not(':empty').length;
            if (count == 1) {
                $('.custom-select .select-selected').addClass("removeAfter");
            }
        });
    }, 600);
});


setTimeout(function () {
    if ($("#basketAssocSlider").length > 0) {
        buyNowSwiper = new Swiper("#basketAssocSlider", {
            slidesPerView: 2.5,
            spaceBetween: 15,
            slidesOffsetBefore: 150,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        $("#basketAssocSlider .swiper-slide").each(function () {
            $(this).find(".prix_product_range p").wrapAll("<div class='description_wrap'></div>");
            $(this).find(".prix_product_range .price_tag").wrapAll("<div class='description_wrap_price'></div>");
        });
    }
}, 150);

function showLightBoxSelectionOfferte() {
    $.ajax({
        type: 'post',
        url: path_relative_root+'ajax_lightbox_selection_offerte.php',
        success: function( response ) {
            $('#shade').addClass('visible')
            if ($('#selectionOffertePopup').length == 0) {
                $(".encart_selection_offert").html(response);
            }

            // Si le nombre de selection égale 1 on ajoute le produit directement dans le panier, sinon on affiche la lightBox avec la selection
            if (nb_selection_offert == 1) {
                var id_produit_offert_unique = $('.ligne_produit_offert').length == 1 ? $('.ligne_produit_offert').data('idproduit'):0;
                if ($('.ligne_produit_offert').length == 1 && $("#qteProd_" + id_produit_offert_unique).val()>0) {
                    $('.ligne_produit_offert').addClass('seleted');
                }
                ajoutLightBoxSelectionOfferte();
            } else {
                $('#selectionOfferteContainer').show();
            }
        }
    });
}

function deleteArticle(obj,id) {
    $('#prod_' + id).remove();
}

function choiceDetailProduct(id, element,type) {
    var current_size = $('#size_sel_offer_' + id).val();
    var current_color = $('#color_sel_offer_' + id).val();

    if (type == 'size' && element !== current_size)  {
        $('#size_sel_offer_' + id).val(element);

        $('#form_produit_offert_' + id + ' .size_list label').each(function() {
            if($(this).attr('title') !== element && $(this).hasClass("actif")){
                $(this).removeClass('actif');
            }
        });

        $('#form_produit_offert_' + id + ' #size' + '_' + element).addClass('actif');
    } else if (type == 'color' && element !== current_color) {
        $('#color_sel_offer_' + id).val(element);

        $('#form_produit_offert_'+ id +' .color_list label').each(function() {
            if($(this).attr('title') !== element && $(this).hasClass("actif")){
                $(this).removeClass('actif');
            }
        });

        $('#form_produit_offert_' + id + ' #color' + '_' + element).addClass('actif');
    }
}

function ajoutLightBoxSelectionOfferte() {
    var tab_prod_offert = new Array();
    var total_prod_offert_selec = 0;
    var totalProdActiv = 0;
    var selection = $('.ligne_produit_offert.seleted').find('.form_selection');

    selection.each(function() {
        totalProdActiv++;

        var id_bloc = $(this).attr('id');
        var tab_response = id_bloc.split('_');
        var id = tab_response[3];
        var is_checked = $('#prod_' + id).hasClass('seleted');
        var taille_prod = $('#size_sel_offer_' + id).val();

        if ( typeof(taille_prod) == 'undefined' ) {
            taille_prod = 0;
        }

        var $bloc_couleur_prod = $('#color_sel_offer_'+id);
        var couleur_prod = $bloc_couleur_prod.val();
        var id_prod = $bloc_couleur_prod.data('produit_id');

        id_prod = id_prod != undefined ? id_prod : id;

        if ( typeof(couleur_prod) == 'undefined' ) {
            couleur_prod = 0;
        }

        var qte_prod = 0;

        if (!Number.isInteger(qte_prod) && Number.isInteger(id_prod)) {
            qte_prod = $("#qteProd_" + id_prod).val();
        }

        if (is_checked) {
            qte_prod = 1;

            if (taille_prod !== "" && couleur_prod !== "") {
                tab_prod_offert.push(new Array( id_prod, taille_prod, couleur_prod, qte_prod ));
                total_prod_offert_selec += parseInt(qte_prod);
            }
        }
    });

    if ( total_prod_offert_selec > 0 && total_prod_offert_selec <= nb_selection_offert_restant ) {
        var cnt_prod_offert = tab_prod_offert.length;

        $.ajax({
            type: 'post',
            data: {
                tab_prod_offert: tab_prod_offert
            },
            url: path_relative_root+'ajax_ajout_selection_offerte.php',

            success: function( response ) {
                location.reload();
                $('.btn_choose_product').removeClass('loading');
            }
        });
    } else {
        var $wp = $('.wrapper_product').css({position : 'relative'});
        var $alert = $('<div class="alert"><span></span></div>');

        $wp.before($alert);

        if (totalProdActiv == 0) {
            if (Error1 != undefined && Error1 != '') {
                $('span',$alert).html(Error1);
            } else {
                $('span',$alert).html(Translator.translate('one_product_min'));
            }
        } else {
            if (Error2 != undefined && Error2 != '') {
                $('span',$alert).html(Error2);
            } else {
                $('span',$alert).html(Translator.translate('select_carac_product'));
            }
        }

        $alert.addClass("actif");
        $(".modal_shade").addClass("visible");

        $('body').click(function (e) {
            if ($alert) {
                $alert.removeClass("actif");
                $(".modal_shade").removeClass("visible");
            }
        });

        $('.btn_choose_product').removeClass('loading');
    }
}

var old_obj = null;
var old_parent = null;
var old_id = null;

function activeFormProduitOffert( obj, id, event ) {
    var parent = $(obj);

    obj = $('.btn_radio',parent);

    var container = $(obj).parents('.content_product');
    var clickable;

    if (old_obj == null) {
        old_obj = obj;
        old_parent = $(obj);
        old_id = id;
    }

    if ($(event.target).closest('.third_bloc_produit_offert').length == 0 && nb_selection_offert_restant > 0) {
        clickable = true;
    } else {
        clickable = false;
    }

    if (clickable == true) {
        if (!parent.hasClass('disable')) {
            if ( obj.hasClass('actif') ) {
                stop_prod_offert = false;

                $( '#selectionOffertePopup .ligne_produit_offert .third_bloc_produit_offert' ).each(function() {
                    $(this).parent().removeClass('inactif');
                });

                obj.removeClass('actif');
                parent.removeClass('seleted');

                $("#form_produit_offert_"+id).stop(true,true).css({display : 'block', opacity : 1}).animate({opacity : 0},function() {
                    $("#form_produit_offert_"+id).css({opacity : '',display : 'none'});
                    $("#liste_qte_"+id).html('');
                });

                if (typeof nbrGiftProd == 'number' ) {
                    nbrGiftProd++;
                    $('#nb_prod_selection_offerte_lightbox').html(nbrGiftProd);
                }
            } else {
                if ( !stop_prod_offert && nbrGiftProd != 0) {
                    showQteListeGamme('liste_qte_' + id, id, path_relative_root);

                    $("#form_produit_offert_" + id).stop(true,true).css({display : 'block', opacity : 0}).animate({opacity : 1},function() {
                        $("#form_produit_offert_" + id).css({opacity : ''});
                    });

                    obj.addClass('actif');
                    parent.addClass('seleted');

                    var targetForm = $("#form_produit_offert_"+id);
                    var formPos = targetForm.offset().top - ((targetForm.height()) * 1.8);

                    $('html, body').animate( { scrollTop: formPos }, 600 );

                    if (typeof nbrGiftProd == 'number') {
                        nbrGiftProd--;
                        $('#nb_prod_selection_offerte_lightbox').html(nbrGiftProd);
                    }

                    old_obj = obj;
                    old_parent = parent;
                    old_id = id;
                } else if (!stop_prod_offert && nbrGiftProd == 0) {
                    // désélection
                    stop_prod_offert = false;
                    old_obj.removeClass('actif');
                    old_parent.removeClass('seleted');

                    $("#form_produit_offert_" + old_id).stop(true,true).css({display : 'block', opacity : 1}).animate({opacity : 0},function() {
                        $("#form_produit_offert_" + old_id).css({opacity : '',display : 'none'});
                        $("#liste_qte_" + old_id).html('');
                    });

                    // séléction
                    setTimeout(function() {
                        $( '#selectionOffertePopup .ligne_produit_offert .third_bloc_produit_offert' ).each(function() {
                            $(this).parent().removeClass('inactif');
                        });

                        showQteListeGamme('liste_qte_' + id, id, path_relative_root);

                        $("#form_produit_offert_" + id).stop(true,true).css({display : 'block', opacity : 0}).animate({opacity : 1},function() {
                            $("#form_produit_offert_" + id).css({opacity : ''});
                        });

                        obj.addClass('actif');
                        parent.addClass('seleted');

                        var targetForm = $("#form_produit_offert_" + id);
                        var formPos = targetForm.offset().top - ((targetForm.height()) * 1.8);

                        $('html, body').animate( { scrollTop: formPos }, 500 );
                    }, 500);

                    old_obj = obj;
                    old_parent = parent;
                    old_id = id;
                }
            }

            $('.ligne_produit_offert').each(function() {
                if ($(this).hasClass('disable')) {
                    $(this).removeClass('disable');
                    $(this).stop(true).css({display : 'block'}).animate({opacity : 1},300,function() {
                        $(this).css({opacity : ''});
                    })
                } else {
                    $(this).removeClass('disable');
                }
            });
        }
    }
}

if ($('body.product_page').length) {
    $(function() {
        //caches a jQuery object containing the header element
        var body_mobile_fp = $("body");

        $(window).scroll(function() {
            var scroll_mobile_fp = $(window).scrollTop();

            if (scroll_mobile_fp >= 60) {
                body_mobile_fp.removeClass('hide_header').addClass("show_header");
            } else {
                body_mobile_fp.removeClass("show_header").addClass('hide_header');
            }
        });
    });
}

function initMultilpleSuperSwiper() {

    var superHomeSwiper = $('.super_home_swiper')

    if (superHomeSwiper.length) {
        superHomeSwiper.each(function () {

            if ($(this).find('.swiper-slide').length > 1) {
                var superHomeCover = new Swiper($(this), {
                    preloadImages: false,
                    lazy: true,
                    loop: true,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                });
            }
        })
    }
}



function dataLayer_associated_product () {

    if (window.$('#gondole_aimerez')) {

        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;

        if (product_id !== '') {
            if (typeof data_layer_products != 'undefined' && data_layer_products.length > 0) {
                data_layer_products.forEach(function (item) {
                    if (item.id == product_id) {
                        productObj = item;
                    }
                })
            }
            if (typeof data_layer_products_aimerez != 'undefined' && data_layer_products_aimerez.length > 0) {
                var objs = Object.keys(data_layer_products_aimerez);
                var values = Object.values(data_layer_products_aimerez);

                for (var i = 0; i < objs.length; i++) {
                    if (values[i].id == product_id) {
                        productObj = values[i];
                    }
                }
            }

            if (typeof data_layer_products_look != 'undefined' && data_layer_products_look.length > 0) {
                var objs = Object.keys(data_layer_products_look);
                var values = Object.values(data_layer_products_look);

                for (var i = 0; i < objs.length; i++) {
                    if (values[i].id == product_id) {
                        productObj = values[i];
                    }
                }
            }
            var prod = productObj;

            if (productObj !== null && productObj !== undefined) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });

                dataLayer.push({
                    'event': 'associatedProductClick',
                    'assoc_product': prod
                });
            }
        }

    }
}

// Function to remove account details such as information, account, newsletter swiper when search page is displayed
function removeCustomerDetail(page) {
    if ($('body.'+ page).length) {
        setTimeout(() => {
            if ($('body.'+ page +' .dynasearchwrapper').length) {
                $('body.'+ page +' #wrap_bonjour').hide();

            } else {
                $('body.'+ page +' #wrap_bonjour').show();
            }
        }, 300);

        $('body.'+ page +' #search_home').on('input', function() {
            var enteredText = $(this).val();
            if (enteredText.length > 2) {
                setTimeout(() => {
                    $('body.'+ page +' #wrap_bonjour').hide();
                }, 300);

            } else {
                setTimeout(() => {
                    $('body.'+ page +' #wrap_bonjour').show();
                }, 300);
            }
        });
    };
}

$(function() {
    var pages = [
        'customer',
        'newsletter',
        'wishlist',
        'order',
        'faq'
    ];

    for (var i = 0; i < pages.length; i++) {
        removeCustomerDetail(pages[i]);
    }
});

function seeXProduct(nb_product, page) {

    var nb_prod_default = parseFloat($('#nb_prod_default').val());
    var idObj = $("#idObj").val() != 'undefined' ? $("#idObj").val() : null;

    //Pour afficher tout, on repart de la page courante
    if (nb_product === (nb_prod_default + 1)) {

        $('#scroll_origin').val($("#initial_page").val());
        $('#page').val(page);

        var type = $("#type").val() != 'undefined' ? $("#type").val() : null;

        //On force à 11 pour conserver l'organisation de la page
        nb_product = nb_prod_default;
        $("#see_all").val("1");
        generateNewBlocProd(type, idObj, (page - 1), nb_product);
        $("#initial_page").val(page);

    } else {
        var type = $('#type_tri').val();
        $("#see_all").val("0");
        $("#nb_prod").val(nb_product);
        $("#initial_page").val(1);

        // Si on est sur la page de recherche
        if ($("#search_page").length > 0) {
            generateNewBlocSearch(0);
        } else {
            generateNewBlocProd(type, idObj, 0);
        }
    }
}

// Allow proper shade styling when on user menu dropdown (header)
function toggleAccountMenuExtra() {
    if ($('#account_menu').hasClass('active')) {
        $('#shade').addClass('user_dropdown');
    } else {
        $('#shade').removeClass('user_dropdown');
    }
}

// Close user menu dropdown (header) when opening side menu
function toggleMenuExtra() {
    if ($('#account_menu').hasClass('active')) {
        $('#account_menu').removeClass('active');
        $('#shade').removeClass('user_dropdown');
    }
}

// Check request validity of the order return
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });
});

function changeSeoElementWithPagination(res) {
    // This block of code updates the SEO-related link elements in the HTML document.
    let canonical = res[5];
    let prev = res[6];
    let next = res[7];

    $("link[rel^='canonical']").attr("href", path_root + canonical);
    $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

    $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

    let hrefPrev = $("link[rel^='prev']");
    let hrefNext = $("link[rel^='next']");
    let commentPrev = $('#href_prev');
    let commentNext = $('#href_next');

    changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
    changeSeoElement(next, hrefNext, commentNext, 'next');
}


// Goes to given page
// Works with pager in shop districts
function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    const productLoaded = $('#list_item .item_container:not(".push")').length;
    const maxItems = document.getElementById('totalElems').value;

    if (fromScrollBottom !== undefined && fromScrollTop !== undefined && ((page - 1) >= Math.ceil(maxItems / parseInt($('#nb_prod').val())) || (productLoaded == maxItems))) {
        // Stop infinite scroll if last page reached or all items loaded on page
        $('#is_loading').val('0');
        $('#initial_page').val(page - 1);
        return false;
    }

    if (fromScrollBottom === undefined && fromScrollTop === undefined) {
        //Update loaded page only on normal pagination for ref, not infinite scroll
        $('#loaded_page').val(page);
        $('#is_loading').val('1');
        isclicked = false;
    }

    $('#is_loading').val('0');
    page = page || 1;

    var type = document.getElementById('type_tri').value;
    var idObj = getCurrentIdRayon();
    var bloc_page = page - 1;
    var content_page = '';

    if (fromScrollBottom || fromScrollTop) {
        $(".loader_scroll").fadeTo("fast", 1);
        $('#is_loading').val('0');
    } else {
        $('#initial_page').val(page);
    }

    if (from_type == 'product') {
        generateNewBlocProd(type, idObj, bloc_page, null, null, fromScrollBottom, fromScrollTop);
    } else {
        generateNewBlocSearch(document.getElementById('nb_prod').value, bloc_page, null, fromScrollBottom, fromScrollTop);
    }
}


// Refactoring needed
function generateNewBlocProd(type, idObj, page, nb_product, btn, fromScrollBottom, fromScrollTop) {
    if ($('.see_all')) {
        $('.see_all').addClass('loading');
    }

    var list_id_div = [];

    output = triggerFilter(page, type, idObj, nb_product, 'filters_form');
    var nb_product = nb_product || parseFloat($('#nb_prod').val());

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = page * nb_product;
        is_from_page = true;
    }

    if ($('#oneProduct').hasClass('actif')) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }
    // Add misc values to output object
    output.page = this.page.value;

    output.parentPath = path_relative_root;
    if (!use_new_filter_url) {
        output.idObj = idObj;
        output.type = type;
    }

    if (output.page != 0) {
        output.nb_prod = nb_product;
    } else {
        // Since we are on the first page, we need to reset the number of products to the default value because pushs are set only on the first page and are repeated manually on others
        output.nb_prod = $('#nb_prod_default').val();
    }

    output.is_page = is_from_page;
    output.nb_tot_prod = this.totalElems.value;
    output.object_id = this.object_id.value;

    output.handle_push = 'false';

    if ($('#prevNSAncre').length > 0) {
        let currentPage = $('#initial_page').val();
        output.current_page = parseInt(currentPage);
    }

    if (typeof id_category_super != 'undefined')
        output.id_category_super = id_category_super;

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
        output.multi_object = $('#multi_object').val();
    }

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    /** LEGACY **/
    //Recuperation du tri par prix (descendant ou ascendant) qui passe, malheureusement, par l'url
    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html');
    var pattern2 = new RegExp('\/e([0-9]*)\.html$');
    var tri = '';
    // Si il y a un ordre dans les prix on charge l'id
    if (pattern.test(url)) {
        var res = url.match(pattern);
        tri = res[1][0];
    }
    /** END LEGACY **/
    if (tri != 0) {
        output.tri = tri;
    }

    if (page === 'all') {

        /** LEGACY **/
        hash = location.hash.substr(1);
        if ((/i=[0-9]+/).test(hash)) {
            // Vu que le header est en absolute on le sort du calcul
            var marginTop = $('.banner_wrapper').height() + 10,
                $elem = $('#' + hash.replace('=', '')),
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function () {
                $(window).scrollTop(top);
            }, 1000);
        }

        /** END LEGACY **/
    } else {
        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + 'ajax_reload_products.php',
            type: 'get',
            data: output,
            success: function (res) {
                //var new_elm = document.createElement('div');
                var old_elm = document.querySelector('.list_item');
                var content, itm_length;
                var is_in_wishlist = [];

                if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {

                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();

                itm_length = parseFloat(res[1]);

                changeSeoElementWithPagination(res);

                if ($('.rayonCategSlider').length && output.object_id) {

                    $('.rayonCategSlider .menuitem').removeClass('actif');
                    $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                    var rayonInitialSlide;

                    $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                        if ($(obj).hasClass('actif')) {
                            rayonInitialSlide = i;
                        }
                    });

                    if (typeof rayonCategSlider != 'undefined') {
                        rayonCategSlider.slideTo(rayonInitialSlide);
                    }
                } else {
                    $('.rayonCategSlider .menuitem').removeClass('actif');
                }

                if (content !== '') {
                    // new_elm.className = 'list_item';
                    // new_elm.innerHTML = content;

                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".loader_scroll").css("display", "none");
                    } else {
                        old_elm.innerHTML = "";
                    }

                    if (type_nav_product === "pagination") {
                        $(".loader_scroll").css("display", "none");
                    }

                    document.getElementById('nbr_items').innerHTML = itm_length;
                    document.getElementById('totalElems').value = itm_length;

                    paginate(page + 1);

                    //old_elm.appendChild(new_elm);

                    if (fromScrollTop) {
                        var firstMsg = $('#scroll_items .item_container:first');
                        var curOffset = firstMsg.offset().top - $(document).scrollTop();
                        old_elm.insertAdjacentHTML('afterbegin', content);
                        $(document).scrollTop(firstMsg.offset().top - curOffset);
                        //L'inverse pour le bas
                    } else {
                        console.log(content);
                        var count = (content.match(/item_container/g) || []).length;
                        console.log(count);
                        old_elm.insertAdjacentHTML('beforeend', content);
                    }

                    if (one_product) {
                        $('div.item_container').addClass('full');
                    }

                    if (parseInt(output.nb_tot_prod) > parseInt(output.nb_prod)) {
                        //backToTop();
                    }

                    /** LEGACY **/

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if (js_compteur != null) {
                        try {
                            var cnt_compteur = js_compteur.length;

                            for (var i = 0; i < cnt_compteur; i++) {
                                eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                            }
                        } catch (e) { }
                    }
                    if (js_encart != null) {
                        try {
                            js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                            eval(js_encart);
                        } catch (e) { }
                    }

                    /** END LEGACY **/

                    if (parseFloat($('#filters_menu').css('right')) === 0) {

                        toggleMenu('filters', 'right');
                    }

                    if (window.lazyload || typeof lazyload != 'undefined') {

                        lazyload.init();
                    }

                    if (typeof lazyloadImage === 'function') {
                        lazyloadImage();
                    }

                    if (typeof generateNewBlocSearchExtra === 'function') {
                        generateNewBlocProdExtra();
                    }

                } else {
                    reloadProductBlocNoResults();
                }
                filtre_ajax();

                is_in_wishlist = (typeof res[9] === 'undefined') ? [] : res[9].split(',');

                for (const product_id in is_in_wishlist) {
                    var pdt_id = (is_in_wishlist[product_id].includes('_')) ? is_in_wishlist[product_id].split('_')[0] : is_in_wishlist[product_id];
                    if ($("div").find('[data-productid=' + pdt_id + ']').attr('data-productid') !== '') {
                        var id_div = $("div").find('[data-productid=' + pdt_id + ']').attr('id');
                        list_id_div.push(id_div);
                    }
                }
            },
            complete: function () {
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                var isInfinityScroll = $('#see_all').val() == 1;
                var clickingOnSeeAllProducts = nb_product === '1000' || nb_product === 'all';

                /**
                 * Retour vers le haut du rayon :
                 * uniquement dans le cas d'une pagination classique (see_all.val() == 0).
                 * Si initialisation du scroll infini (see_all.val() == 1), on ne remonte que de quelques
                 * px pour déclencher le chargement de la page suivante.
                 */
                if (!fromScrollBottom && !fromScrollTop) {

                    if ($('.item_container:not(.push)').length <= nb_product + 1) { // Exclude push in check

                        /**
                         * Dans le cas où le scroll infini est configuré
                         * et que l'on veut l'activer
                         */
                        if (isInfinityScroll) {
                            window.scrollTo(
                                0,
                                ($('#scroll_items').offset().top // Espace entre le haut de la page et le haut du block #scroll_items
                                    + ($('#scroll_items').height() // Hauteur totale du block #scroll_items
                                        - $('.item_container').height() // Hauteur d'un item de produit
                                        * 2))
                            );
                        }

                        /**
                         * Dans le cas où le scroll infini n'est pas configuré
                         * et que l'on veut afficher tous les produits du rayon
                         */
                        else if (!isInfinityScroll && clickingOnSeeAllProducts) {
                            setTimeout(function () {
                                var itemHeight = $('.item_container:not(.push)').height(); // Hauteur d'un item de produit
                                var countPush = $('.item_container.push').length ? $('.item_container.push').length : 0;
                                var itemPushHeight = $('.item_container.push').length ? $('.item_container.push').height() : 0;
                                var changeViewEnabled = $('#toggleNumObjects').length; // Possibilité de changer de vues

                                if (changeViewEnabled) {
                                    var isViewPerOne = changeViewEnabled && $('#oneProduct').hasClass('actif'); // Vérif sur le mode de vue(s) du rayon
                                } else {
                                    var isViewPerOne = 1;
                                }

                                /**
                                 * Pour calculer le nombre de lignes de produits en vue par 2
                                 * on ajoute 1 si le nombre de produits est impair
                                 * pour avoir un réultat rond
                                 */
                                if (!(defaultNbProd % 2 == 0) && !isViewPerOne) {
                                    defaultNbProd = defaultNbProd + 1;
                                }

                                /**
                                 * La hauteur de la liste de produits
                                 * égale à la hauteur d'un item de produit
                                 * multipliée par le nombre de produits
                                 * ou le nombre de nombre de ligne de produits (en vue par 2)
                                 */
                                var defaultListItemsHeight = isViewPerOne ? itemHeight * defaultNbProd + (itemPushHeight * countPush) : itemHeight * (defaultNbProd / 2) + (itemPushHeight * countPush);

                                window.scrollTo(
                                    0,
                                    defaultListItemsHeight
                                );
                            }, 100);
                        }

                        /**
                         * Dans le cas où l'on utilise la pagination
                         */
                        else {
                            backToTop();
                        }
                    } else {
                        setTimeout(function () {
                            if ($('#see_all').length > 0 && $('#see_all').val() == 0) {

                                $("html, body").animate({
                                    scrollTop: 0
                                }, 200);

                            } else {

                                $("html, body").animate({
                                    scrollTop: $(window).scrollTop() - 2
                                }, 200);

                            }
                        }, 250);
                        //reset initialPage if from filter
                        $("#initial_page").trigger("change");
                    }
                }

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');

                    /**
                     * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                     * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                     */

                    if ($('body.category').length) {
                        var origin = $('.item_container:not(.push)').find('img.watched'),
                            target = $('span.ill_img'),
                            targetHeight = origin.height();

                        target.each(function () {
                            $(this).css({ minHeight: targetHeight });
                        })
                    }
                }

                if (fromScrollBottom && !fromScrollTop) {
                    $("#initial_page").val(page + 1);
                }

                setTimeout(function () {

                    var loader = $('.button + .loader');

                    if (loader.hasClass('loading')) {

                        loader.removeClass('loading');
                    } else {

                        loader.parent().removeClass('loading');
                    }

                    if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {

                        var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');

                        toggleMenu(menuId);
                    }
                }, 200);

                if (list_id_div.length > 0) {
                    for (const class_update in list_id_div) {
                        $("#" + list_id_div[class_update]).removeClass('addToWishlistButton');
                        $("#" + list_id_div[class_update]).addClass('existToWishlistButton');
                    }
                }
            }
        });
    }
}